#loading-container {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: 1rem;
	width: 100%;
}

#loading-container span {
	font-size: 1.5rem;
	margin-top: 1rem;
}

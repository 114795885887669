* {
	box-sizing: border-box;
	font-family: "Google Sans", "Roboto", "Arial", "sans-serif";
	margin: 0;
	padding: 0;
}

::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: gray;
	border-radius: 0.25rem;
}

::-webkit-scrollbar-track {
	background: transparent !important;
}

a {
	all: unset;
	cursor: pointer;
	text-decoration: underline;
}

svg[data-testid="ArrowDropDownIcon"] {
	transition: transform 0.35s;
}

ul {
	list-style-position: inside;
}

.fade-in {
	animation: fade-in 1s;
}

.grecaptcha-badge {
	visibility: hidden;
}

.MuiAccordion-root::before {
	background-color: transparent !important;
}

.selected svg[data-testid="ArrowDropDownIcon"] {
	transform: rotate(180deg);
}

#bmc-wbtn {
	z-index: 0 !important;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
}

#root > div {
	display: flex;
	flex: 1;
	flex-direction: column;
}

@font-face {
	font-family: "Google Sans";
	src: url("./fonts/GoogleSans.ttf");
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: scale(1.1, 1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

@media all and (max-width: 480px) {
	#root {
		min-height: 120vh;
	}
}

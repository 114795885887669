header {
	background: rgb(32, 33, 36);
	display: flex;
	justify-content: center;
	width: 100vw;
}

nav {
	align-items: center;
	display: flex;
	height: 3rem;
	justify-content: space-around;
	width: 25vw;
}

nav a {
	color: white;
	font-size: 1.25rem;
	text-decoration: none;
}

nav > a:last-child {
	display: flex;
}

@media all and (min-width: 481px) and (max-width: 1099px) {
	nav {
		width: 50vw;
	}
}

@media all and (max-width: 480px) {
	nav {
		width: 100vw;
	}
}

#share-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
}

#share-container button {
	border-radius: 1rem;
	font-weight: 500;
}

#share-container > div:first-child {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

#share-container .MuiOutlinedInput-root {
	background: rgba(0, 0, 0, 0.5);
	border-radius: 0.75rem;
}

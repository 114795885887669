.MuiTablePagination-root {
	display: flex;
	justify-content: center;
}

#date-time-requested {
	color: #9aa0a6;
}

#fares-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 1rem;
	height: 100%;
	margin-top: 1rem;
	padding: 1rem;
	width: 100%;
}

#fares-container > div:last-child {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

#fares-container .MuiTablePagination-toolbar .MuiInputBase-root {
	margin-right: 8px;
}

#fares-filters {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: space-between;
}

#fares-filters > div:first-child {
	align-items: center;
	display: flex;
	gap: 0.5rem;
}

#no-options-container {
	align-items: center;
	display: flex;
	justify-content: center;
	flex: 1;
	height: 100%;
}

#no-options-container div {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

#no-options-container svg {
	font-size: 5rem;
}

#skeleton-container {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

#skeleton-container > span {
	border-radius: 0.5rem;
	height: 3rem;
}

@media all and (max-width: 480px) {
	.MuiTablePagination-actions > button {
		padding: 0.25rem !important;
	}

	.MuiTablePagination-actions,
	.MuiTablePagination-input {
		margin: 0 !important;
	}

	.MuiToolbar-root {
		padding: 0 !important;
	}

	#fares-filters {
		justify-content: center;
		margin-top: 1rem;
	}
}

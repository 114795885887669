main {
	align-items: center;
	background: rgb(32, 33, 36);
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100vw;
}

.fade-in-translate {
	animation: fadeInTranslate 2s;
}

.main-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: hidden;
	padding-bottom: 2rem;
	width: 1000px;
}

.recent-search-button {
	align-items: center;
	background-color: red;
	border-radius: 1rem;
	cursor: pointer;
	display: flex;
	gap: 1rem;
	justify-content: space-around;
	padding: 1.5rem;
	text-decoration: none;
	transition: background-color 0.25s;
	white-space: nowrap;
	width: min-content;
}

.recent-search-button p {
	font-size: 1.25rem;
}

.recent-search-button:hover {
	background-color: rgb(200, 0, 0);
}

#recent-search-button-container {
	align-items: center;
	display: flex;
	gap: 2rem;
}

@keyframes fadeInTranslate {
	0% {
		opacity: 0;
		transform: translate(0, 2rem);
	}
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@media all and (min-width: 481px) and (max-width: 1099px) {
	.main-container {
		width: 80%;
	}
}

@media all and (max-width: 480px) {
	.hero {
		margin-top: 6rem;
	}

	.main-container {
		width: 90%;
	}

	#recent-search-button-container {
		flex-direction: column;
	}
}

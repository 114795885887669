.section-container {
	align-items: center;
	background-color: rgb(54, 55, 58);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
	border-radius: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: space-around;
	padding: 2rem;
}

.section-container p {
	font-size: 1.25rem;
	text-align: center;
}

#about-container {
	gap: 2rem;
	padding: 2rem 0;
}

#meet-the-devs-container {
	display: flex;
}

#meet-the-devs-container div {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 0 7.5%;
	width: 50%;
}

#meet-the-devs-container figcaption:first-of-type {
	font-size: 1.75rem;
	text-align: center;
}

#meet-the-devs-container figcaption:last-of-type {
	text-align: center;
}

#meet-the-devs-container img {
	border-radius: 50%;
	box-shadow: rgb(32, 33, 36) 0.5rem 0.5rem;
	margin-bottom: 0.5rem;
	width: 100%;
}

#meet-the-devs-container p {
	text-align: center;
}

@media all and (max-width: 480px) {
	#meet-the-devs-container {
		flex-direction: column;
		gap: 4rem;
	}

	#meet-the-devs-container div {
		width: 100%;
	}

	#meet-the-devs-container figure {
		width: 75%;
	}
}

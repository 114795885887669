footer {
	align-items: center;
	background: black;
	bottom: 0;
	display: flex;
	gap: 0.5rem;
	height: 3rem;
	justify-content: center;
	width: 100vw;
}

footer > * {
	color: lightgray !important;
}

footer > a {
	align-items: center;
	display: flex;
	gap: 0.5rem;
}

footer > div {
	align-items: center;
	display: flex;
	gap: 0.5rem;
}

footer > span:first-child {
	cursor: pointer;
	text-decoration: underline;
}

.vertical-bar {
	background-color: rgba(128, 128, 128, 0.5);
	height: 1.25rem;
	width: 1px;
}

#disclaimer a {
	color: white;
}

@media all and (max-width: 800px) {
	footer {
		flex-direction: column;
		gap: 1rem;
		height: 10.5rem;
	}

	.vertical-bar {
		display: none;
	}
}

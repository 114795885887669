form svg[data-testid="CalendarMonthIcon"] {
	color: white !important;
}

.css-1sxhwwk-MuiButtonBase-root-MuiPickersDay-root:focus {
	background-color: transparent !important;
}

.MuiDateCalendar-root {
	height: auto !important;
	margin: 0;
	overflow-x: clip !important;
	overflow-y: visible !important;
	transform: scale(1.1);
	width: auto !important;
}

.MuiTimePicker-root {
	width: 10rem;
}

.MuiPickersDay-root {
	border-style: dashed none !important;
	border-width: 2px !important;
	margin: 0 !important;
	padding: 1.25rem !important;
}

.MuiPickersDay-root.Mui-selected {
	background-color: #89b3f7 !important;
}

.MuiPickersLayout-root {
	margin-bottom: 1rem;
	overflow: visible !important;
}

.MuiPickersSlideTransition-root {
	overflow: visible !important;
}

.textfield-disabled input {
	-webkit-text-fill-color: #c3c3c4 !important;
}

#date-range-container {
	flex: 1;
	min-width: 16rem;
}

#date-range-container > * {
	width: 100%;
}

#date-range-container > div > div {
	display: flex;
	gap: 1rem;
}

#date-range-container input {
	text-align: center;
}

#date-range-highlight {
	text-decoration: underline solid #9dc0f9;
	border-radius: 0.25rem;
	padding: 0.25rem;
}

#date-range-popover li {
	background-color: transparent !important;
	color: #c3c3c4 !important;
}

#date-range-popover .MuiPickersLayout-root {
	background-color: transparent;
}

#date-range-popover .MuiTabs-root {
	padding: 0 1rem;
}

#date-range-string {
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 1.5rem;
	text-align: center;
	width: 100%;
}

#date-range-text-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
}

#date-range-text-container .MuiButton-root {
	font-size: 0.75rem;
	padding: 0.25rem 0.5rem;
}

#date-range-text-container div:nth-child(2) {
	display: flex;
	gap: 1rem;
}

#date-range-warning {
	align-items: center;
	color: #c3c3c4 !important;
	display: flex;
	gap: 1rem;
	padding: 0 1rem;
}

#date-picker-container > div {
	min-width: 0;
	padding: 0 1rem;
}

#trip-duration-container {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 1rem;
}

@media all and (min-width: 481px) {
	#date-range-popover {
		min-width: 23rem;
	}
}

#map-container {
	display: flex;
	filter: drop-shadow(0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.7));
	flex: 1;
	flex-direction: column;
	position: relative;
	width: 100%;
}

#map-svg {
	border-radius: 1.5rem;
	flex: 1;
	padding: 1rem 0;
	width: 100%;
}

#route-box {
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 1rem 0 1rem 0;
	color: black;
	display: flex;
	gap: 0.5rem;
	left: 0;
	padding: 0.75rem;
	position: absolute;
	top: 1rem;
}

#zoom-out-button {
	cursor: default;
	margin: 1rem;
	opacity: 0;
	pointer-events: auto;
	position: absolute;
	right: 0;
	top: 1rem;
	transition: transform 0.25s;
}

#zoom-out-button:hover {
	transform: scale(1.25);
}

@media all and (max-width: 480px) {
	#map-svg {
		height: 100%;
	}
}

h1 {
	font-size: 3.5rem;
	font-weight: 500;
	position: relative;
}

h1 span {
	background: red;
	border-radius: 0.75rem;
	bottom: 0.5rem;
	font-size: 0.75rem;
	height: 1.5rem;
	padding: 0.25rem;
	position: absolute;
	right: -1rem;
	text-align: center;
	width: 1.5rem;
}

h2,
h2 em {
	font-size: 1.5rem;
}

h2,
h3 {
	font-weight: 400;
}

#hero-container {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: -1.5rem;
	overflow-x: visible;
	position: relative;
	top: -1.5rem;
}

#progress {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

@media all and (max-width: 480px) {
	h1 {
		font-size: 3rem;
	}

	#hero-container {
		top: -0.5rem;
	}

	#progress-train {
		bottom: 20vh;
	}
}

.connection {
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 0.5rem;
	display: flex;
	gap: 0.25rem;
	padding: 0.25rem 0.5rem;
}

.connection img {
	height: 1rem;
}

.connection span {
	font-size: 0.8rem;
}

.dot {
	margin: 0 0.5rem;
}

.dot-end {
	border: 2px solid #5f6368;
	border-radius: 12px;
	height: 12px;
	width: 12px;
}

.dot-line {
	border-right: 4px dotted #5f6368;
	flex: 1;
	margin: 4px;
	width: 4px;
}

.layover-container > div {
	align-items: center;
	display: flex;
	gap: 1rem;
}

.leg-container {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: space-between;
	padding: 1rem 0.5rem;
}

.leg-info-container {
	display: flex;
	gap: 1rem;
	max-width: 21rem;
	min-width: 15rem;
}

.leg-info-container > div:first-child {
	display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	position: relative;
}

.leg-info-container > div:first-child > div:last-child {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	width: 100%;
}

.leg-info-container > div:last-child {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	justify-content: space-between;
}

.leg-info-container > div:last-child > div:nth-last-child(2) {
	color: #9aa0a6;
	display: flex;
	justify-content: space-around;
}

.leg-info-container > div:last-child > div:nth-last-child(2) div {
	display: flex;
	gap: 0.25rem;
}

.leg-info-container > div:last-child > span {
	color: #9aa0a6;
}

.leg-accommodation-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	max-width: 15rem;
}

.leg-accommodation-container > div {
	align-items: center;
	border: 2px solid #5f6368;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	height: 80%;
	justify-content: center;
	padding: 1rem;
}

.leg-accommodation-container div div {
	align-items: center;
	color: #9aa0a6;
	display: flex;
	font-size: 0.9rem;
	flex-direction: column;
	gap: 0.25rem;
}

.leg-accommodation-container div span {
	text-align: center;
}

.leg-amenities-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 10rem;
}

.leg-amenities-container > div {
	background: rgba(255, 255, 255, 0.05);
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	height: min-content;
	gap: 0.5rem;
	padding: 0.75rem;
}

.leg-amenities-container > div > div {
	align-items: center;
	display: flex;
	gap: 0.5rem;
}

.MuiAlert-root {
	padding: 0.25rem 1rem;
}

.trip-container > div:first-child {
	background: rgba(255, 255, 255, 0.05);
	border-radius: 0.5rem;
	display: flex;
	justify-content: space-between;
	padding: 0.75rem;
}

.trip-container hr {
	border: none;
	background: #5f6368;
	height: 0.05rem;
}

.MuiAccordionDetails-root {
	display: flex;
	flex-direction: column;
	padding: 0 1rem !important;
}

.MuiAccordionSummary-root {
	padding: 0 1.75rem !important;
}

.option-container {
	border-radius: 1rem !important;
}

.option-summary {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	justify-content: space-between;
	margin-right: 1rem !important;
	row-gap: 0.5rem;
	width: 100%;
}

.station-container {
	background: rgba(255, 255, 255, 0.05);
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	padding: 0.75rem;
}

.station-container > div:first-child {
	display: flex;
	gap: 0.5rem;
}

.station-container > div:last-child {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

@media all and (max-width: 480px) {
	.leg-accommodation-container {
		max-width: 100%;
		width: 100%;
	}

	.leg-container {
		align-items: center;
		flex-direction: column;
		gap: 1rem;
	}

	.option-summary {
		justify-content: space-around;
	}
}

.error-critical {
	animation: shake 0.35s;
	animation-iteration-count: 1;
}

.error-text {
	color: orange;
	opacity: 0.75;
}

.input-row {
	align-items: center;
	display: flex;
	width: 100%;
}

.input-row > div {
	display: flex;
}

.Mui-disabled .MuiOutlinedInput-notchedOutline {
	border-color: rgba(255, 255, 255, 0.23) !important;
}

.MuiIconButton-root:hover,
.not-selected:hover {
	background-color: #3d4149 !important;
}

.options {
	display: flex;
	gap: 0.25rem;
	justify-content: flex-end;
	padding: 0.5rem 1rem;
}

.options button {
	border-radius: 1rem;
	padding: 0.375rem 1rem;
}

.secondary-input {
	justify-content: space-between;
}

.secondary-input .select,
#trip-duration-container .select {
	color: #c3c3c4 !important;
	font-size: 0.9rem !important;
}

.select {
	border-radius: 0.25rem;
	color: #c3c3c4 !important;
	padding: 0 0.75rem;
}

.select[disabled=""] {
	color: rgba(255, 255, 255, 0.3) !important;
}

.select > div {
	background-color: transparent !important;
}

.select svg[data-testid="PersonOutlineIcon"] {
	max-height: 1.25rem;
}

.select > svg:last-child {
	color: #c3c3c4;
	margin-right: 0.5rem;
	width: 1.25rem;
}

.selected {
	background-color: #4c5667 !important;
}

#form {
	background-color: rgb(54, 55, 58);
	border-radius: 0.5rem;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	padding: 0.5rem 1rem 3rem;
	position: relative;
	width: 100%;
}

#form > div:first-child {
	margin-bottom: 0.5rem;
}

#form > div:last-child {
	text-align: center;
}

#form .error-text {
	align-items: center;
	display: flex;
	font-size: 0.8rem;
	gap: 0.5rem;
	position: absolute;
	bottom: 1rem;
	display: flex;
}

#primary-input {
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: space-around;
}

#primary-input button {
	border-color: rgba(255, 255, 255, 0.23);
	transition: transform 0.25s;
}

#primary-input > div .MuiButton-outlined:hover,
#primary-input .MuiOutlinedInput-notchedOutline:hover {
	border-color: white !important;
}

#primary-input div:first-child,
#primary-input .select {
	flex: 1;
}

#primary-input > div:last-child > div > div {
	cursor: text;
}

#primary-input > div:last-child .MuiOutlinedInput-notchedOutline {
	pointer-events: auto !important;
}

#primary-input .MuiAutocomplete-root {
	flex: 1;
	min-width: 8rem;
}

#primary-input .Mui-focused .MuiOutlinedInput-notchedOutline,
#primary-input .MuiOutlinedInput-notchedOutline {
	border-width: 1px;
}

#primary-input .select:first-child {
	padding: 15px;
}

#search-info {
	align-items: center;
	font-size: 0.9rem;
	flex-wrap: wrap;
	gap: 0.5rem;
	justify-content: flex-start;
}

#search-info span {
	white-space: nowrap;
}

@media all and (max-width: 480px) {
	.error-text {
		justify-content: center;
		left: 0;
		width: 100%;
	}

	.secondary-input .MuiButton-root {
		padding: 6px 0;
	}

	.secondary-input .MuiButton-root .MuiButton-endIcon {
		margin-left: 0;
	}

	.secondary-input .MuiSelect-select {
		padding-right: 1rem !important;
	}

	.select {
		padding-left: 0.25rem !important;
		padding-right: 0.25rem !important;
	}

	.select > svg:last-child {
		margin-right: 0;
	}

	#primary-input .MuiAutocomplete-root {
		min-width: auto;
	}

	#search-info {
		justify-content: center;
		width: 100%;
	}

	#settings-button {
		padding: 0;
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(0.25rem);
	}
	50% {
		transform: translateX(-0.25rem);
	}
	75% {
		transform: translateX(0.25rem);
	}
	100% {
		transform: translateX(0);
	}
}
